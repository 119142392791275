import React from 'react'
import "./Header.css"
import { Button,Row,Col, Navbar,Container,Nav,NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';

function Header() {
    return (
    <Navbar className="header_menu_main" bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="#home">Debt Collector</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#link">Accounts</Nav.Link>
            <NavDropdown title="Settings" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Scripts</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">Contact Support</NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    )
}

export default Header
