import React from "react";
import { Button,Row,Col, Navbar,Container,Nav,NavDropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import './Header'
import Header from "./Header";
import CallForm from "./CallForm";

function App() {
  document.title="Debt Caller";
  return (
    <div>
      <Header/>
      <div className="head_slide"></div>
      <CallForm/>
    </div>
  );
}

export default App;
