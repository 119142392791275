import React from 'react'
import { Button,Row,Col, Form } from 'react-bootstrap';
import axios from 'axios';
import "./CallForm.css"
function CallForm() {
    function handleSubmit(e){
        e.preventDefault();
        const {name, phoneNo, debtAmount, language, dayslate} = e.target.elements;
        
        console.log(name.value + debtAmount.value + phoneNo.value + language.value + dayslate.value);
        if (language.value=="French"){
            
                axios.post("https://api.callbot.yitec.net/call",{
                    name: name.value,
                    callNo: phoneNo.value,
                    debtAmount: debtAmount.value,
                    language: language.value,
                    dayslate: dayslate.value
                }).then(res=>{
                    console.log(res);
                    console.log(res.data);
                })
            
        }
        else{
            axios.post("https://api.callbot.yitec.net/call",{
                name: name.value,
                callNo: phoneNo.value,
                debtAmount: debtAmount.value,
                language: language.value,
                dayslate: dayslate.value
            }).then(res=>{
                console.log(res);
                console.log(res.data);
            })
        }
        alert("We've made a call to the debtor");
       
    }
    return (
        <div className="CallForm">
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Form.Group as={Col} controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control id="name" placeholder="Enter debtor name" />
                    </Form.Group>

                    <Form.Group as={Col} controlId="phoneNo">
                    <Form.Label>Phone no.</Form.Label>
                    <Form.Control id="phoneNo" placeholder="Enter debtor mobile phone number" />
                    </Form.Group>
                </Row>


                <Row className="mb-3">
                    <Form.Group as={Col} controlId="debtAmount">
                    <Form.Label>Debt Amount</Form.Label>
                    <Form.Control id="debtAmount"/>
                    </Form.Group>

                    <Form.Group as={Col} controlId="language">
                    <Form.Label>Language</Form.Label>
                    <Form.Select id="language" defaultValue="English">
                        <option>Choose...</option>
                        <option>English</option>
                        <option>French</option>
                    </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} controlId="dayslate">
                    <Form.Label>Days late</Form.Label>
                    <Form.Control id="dayslate" placeholder="Enter days of late payment"/>
                    </Form.Group>
                </Row>

               

                <Button className="submitButton" variant="primary" type="submit">
                    Make a call
                </Button>
            </Form>
        </div>
    )
}

export default CallForm
